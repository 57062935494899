5<template>
  <div class="login_container">
    <div class="login_box">
      <span class="msg_box">{{ loginMsg }}</span>
      <h2>管理员登录</h2>
      <el-row>
        <el-form :model="loginFrom" ref="loginFromRef" :rules="rules" label-width="100px" class="demo-ruleForm">
          <el-form-item prop="name">
            <el-input clearable placeholder="管理员名称" prefix-icon="el-icon-user" v-model="loginFrom.name"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input show-password clearable placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="loginFrom.password" @keyup.enter.native="login"></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <el-row class="btn_row">
        <el-button type="primary" @click="login">登录</el-button>
        <el-button @click="reset">重置</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import apis from '@/apis/apis'
export default {
  data() {
    return {
      // 登录表单信息
      loginFrom: {
        name: '',
        password: ''
      },
      // 登录表单校验规则
      rules: {
        // 用户名校验规则
        name: [
          { required: true, message: '请输入管理员名称', trigger: 'blur' },
          { min: 2, max: 6, message: '长度在 2 到 6 个字符', trigger: 'blur' }
        ],
        // 密码校验规则
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' }
        ]
      },
      // 密码错误提示信息
      loginMsg: ''
    }
  },
  methods: {
    // 登录
    login() {
      this.$refs.loginFromRef.validate(async isValid => {
        if (isValid) {
          const { name, password } = this.loginFrom
          const { data: res } = await this.$POST(apis.login.userLogin, { name, password })
          const { code, data, message } = res
          if (code !== 200 && message !== '成功') {
            this.loginMsg = message
            setTimeout(() => this.loginMsg = '', 2500)
            return
          }
          window.sessionStorage.setItem('token', data.token)
          this.$store.commit('SET_USER_INFO', data)
          this.$router.push({ name: 'Home' })
          this.$message.success('登录成功')
        }
      })
    },
    reset() {
      this.loginMsg = ''
      this.$refs.loginFromRef.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.login_container {
  width: 100%;
  height: 100%;
  // background-color: rgba(255, 192, 203, 0.445);
  // opacity: 0.5;
  position: relative;
  .login_box {
    width: 25%;
    height: 35%;
    background-color: rgba(241, 235, 235, 0.2);
    border: 2px solid rgba(241, 235, 235, 0.452);
    border-radius: 15px;
    padding: 10px 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .msg_box {
      position: absolute;
      bottom: 27%;
      font-size: 14px;
      color: red;
    }

    .el-row {
      width: 100%;
    }
    h3 {
      position: relative;
      // top: -20;
      text-align: center;
    }
    .el-form {
      margin-top: 5%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      .el-input {
        margin: 0.5rem 0;
      }
    }
    .btn_row {
      width: 100%;
      margin-top: 5%;
      display: flex;
      justify-content: center;
      // flex-direction: column;
      // text-align: center;
      align-items: center;
      .el-button:hover {
        transform: scale(1.2);
        transition: 1s;
        // width: 80%;
      }
    }
  }
}
/deep/.el-input {
  // width: 80%;
}
/deep/.el-form-item__content {
  margin: 0 !important;
}
/deep/.el-form-item {
  // margin: 0 !important;
}
/deep/.el-form-item__error {
  font-size: 14px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  // background-color: rgba(0, 0, 0, 0.644);
}
/deep/.el-form-item.is-error .el-input__inner {
  border-color: white;
}
</style>
